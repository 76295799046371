import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-20"},[_c('bkf-widget',[_c('template',{slot:"title"},[_vm._v("\n      "+_vm._s(_vm._f("trans")('report.cyclical'))+"\n    ")]),_vm._v(" "),_c('template',{slot:"actions"},[_c(VBtn,{attrs:{"tile":"","color":"primary"},nativeOn:{"click":function($event){return _vm.configurator.apply(null, arguments)}}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('report.add'))+"\n      ")])],1),_vm._v(" "),(_vm.reportsConfigurations.length)?_c(VDataTable,{attrs:{"headers":_vm.columns,"items":_vm.reportsConfigurations,"items-per-page":_vm.settings.size},scopedSlots:_vm._u([{key:"item.reportType",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm._f("trans")("report.type." + item.reportType))+"\n      ")]}},{key:"item.reportInterval",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm._f("trans")("el.interval." + item.reportInterval))+"\n      ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c(VSpacer),_vm._v(" "),_c(VCol,{staticClass:"pr-0",attrs:{"cols":"2"}},[_c(VTooltip,{attrs:{"bottom":"","activator":_vm.$refs['switch_' + item.id]}},[_c('span',[_vm._v(_vm._s(_vm.switchTooltip(item)))])]),_vm._v(" "),_c(VSwitch,{ref:'switch_' + item.id,staticClass:"mt-1 w40",attrs:{"dense":"","hide-details":""},on:{"click":function($event){return _vm.setEnabledFlag(item)}},model:{value:(item.isEnabled),callback:function ($$v) {_vm.$set(item, "isEnabled", $$v)},expression:"item.isEnabled"}})],1),_vm._v(" "),_c(VCol,{staticClass:"pl-0",attrs:{"cols":"4"}},[_c('bkf-action-edit',{on:{"click":function($event){return _vm.edit(item)}}}),_vm._v(" "),_c('bkf-action-delete',{on:{"click":function($event){return _vm.remove(item)}}})],1)],1)]}}],null,true)}):_c('bkf-empty-state',{attrs:{"show-widget":false,"title":_vm.noReports}})],2),_vm._v(" "),_c('bkf-crud',{ref:"crud",attrs:{"create-submit-label":_vm.$trans('report.action.create'),"update-title":_vm.$trans('report.action.edit'),"delete-title":_vm.$trans('report.action.delete'),"reports-type":_vm.reportsType}}),_vm._v(" "),_c('bkf-page-settings',{attrs:{"settings":_vm.settings},on:{"update:settings":function($event){_vm.settings=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }