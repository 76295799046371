import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-column"},[_c('div',{staticClass:"flex flex-justify-end"},[(_vm.$refs.dialog)?_c(VBtn,{attrs:{"color":"primary","tile":""},on:{"click":_vm.$refs.dialog.open}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('device-operator.assign-devices'))+"\n      ")]):_vm._e()],1)]),_vm._v(" "),(_vm.loaded)?[(_vm.linkedDevices.length)?_c(VDataTable,{attrs:{"headers":_vm.columns,"items":_vm.linkedDevices,"items-per-page":5},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('module-activity-indicator',{staticClass:"py-2",attrs:{"value":_vm.$deep(item, 'active')}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('bkf-action-delete',{on:{"click":function($event){return _vm.unlink([item.id])}}})]}}],null,true)}):_c(VAlert,{staticClass:"mt-0",attrs:{"type":"info"}},[_vm._v("\n      "+_vm._s(_vm._f("trans")('device-operator.assinged-devices-message'))+"\n      "),_c('br'),_vm._v("\n      "+_vm._s(_vm._f("trans")('device-operator.no-assigned-devices'))+"\n    ")])]:_vm._e(),_vm._v(" "),_c('assign-dialog',{ref:"dialog",attrs:{"devices":_vm.unlinkedDevices,"device-groups":_vm.deviceGroups},on:{"submit":_vm.link}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }