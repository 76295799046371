import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"page-switch","mode":"out-in"}},[(_vm.isActivePage)?_c('bkf-page',{staticClass:"pt-3",attrs:{"loading":_vm.loading,"error":_vm.error}},[_c('bkf-widget',{staticClass:"pb-4"},[_c(VRow,[_c(VCol,[_c('field-device-groups',{attrs:{"device-groups":_vm.deviceGroups},model:{value:(_vm.settings.deviceGroups),callback:function ($$v) {_vm.$set(_vm.settings, "deviceGroups", $$v)},expression:"settings.deviceGroups"}})],1),_vm._v(" "),_c(VCol,[_c('field-date-range',{model:{value:(_vm.settings.date.range),callback:function ($$v) {_vm.$set(_vm.settings.date, "range", $$v)},expression:"settings.date.range"}})],1),_vm._v(" "),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"ml-4 mt-2",attrs:{"disabled":!_vm.settingsValid || _vm.fetching,"tile":"","color":"primary"},on:{"click":_vm.generate}},[_vm._v("\n              "+_vm._s(_vm._f("trans")('report.action.generate'))+"\n            ")]),_vm._v(" "),_c(VBtn,{staticClass:"mt-2",attrs:{"disabled":_vm.fetching || _vm.report.length === 0,"tile":"","color":"primary"},on:{"click":_vm.toCSV}},[_vm._v("\n              "+_vm._s(_vm._f("trans")('report.action.export'))+"\n            ")])],1)],1),_vm._v(" "),(_vm.message)?_c('div',{staticClass:"message"},[_c(VAlert,{staticClass:"mt-0",attrs:{"type":"warning"}},[_vm._v("\n            "+_vm._s(_vm._f("trans")(_vm.message))+"\n          ")])],1):_vm._e(),_vm._v(" "),(_vm.report.length)?_c(VDataTable,{attrs:{"headers":_vm.columns,"items":_vm.report,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.alert",fn:function(ref){
var item = ref.item;
return [_c('alerts-table',{attrs:{"devices":item.devices}})]}}],null,true)}):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }