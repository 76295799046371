import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[(_vm.isActivePage)?_c('portal',{attrs:{"to":"page-actions"}}):_vm._e(),_vm._v(" "),(_vm.isActivePage)?_c('bkf-page',{staticClass:"pt-3",attrs:{"loading":false,"error":_vm.error}},[_c('bkf-widget',[_c(VCardTitle,[_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c(VSpacer),_vm._v(" "),_c(VTextField,{staticClass:"font-weight-regular",attrs:{"append-icon":"search","label":_vm.$trans('table.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c(VCol,{staticClass:"pr-0 pt-7",attrs:{"cols":"auto"}},[_c('bkf-action-refresh',{on:{"click":_vm.refresh}})],1)],1),_vm._v(" "),(!_vm.siteLoading)?_c(VDataTable,{attrs:{"loading":_vm.siteLoading,"headers":_vm.columns,"items":_vm.administrationDevices,"footer-props":_vm.footerProps,"options":_vm.pagination,"server-items-length":_vm.totalItems,"single-expand":true,"expanded":_vm.expanded,"item-key":"id"},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("\n              "+_vm._s(item.name)+"\n              "),_c('div',{staticClass:"caption blue-grey--text lighten-1"},[_vm._v("\n                "+_vm._s(item.deviceType.name)+"\n              ")])])]}},{key:"item.deviceGroups.name",fn:function(ref){
var item = ref.item;
return [(item.deviceGroups.length)?_c('bkf-action-launch',{attrs:{"tooltip":"device.go-to-device-group"},on:{"click":function($event){return _vm.redirectToDeviceGroup(item.deviceGroups[0].id)}}},[_vm._v("\n              "+_vm._s(item.deviceGroups[0].name)+"\n            ")]):_vm._e()]}},{key:"item.deviceStatus",fn:function(ref){
var item = ref.item;
return [_c('module-activity-indicator',{staticClass:"my-2",attrs:{"value":item.deviceStatus.online,"last-sync":_vm.$deep(item, 'deviceStatus.lastOnline')}}),_vm._v(" "),_c('enabled-time-indicator',{staticClass:"my-2",attrs:{"value":_vm.$deep(item, 'stats.activeTime'),"is-active":item.deviceStatus.active}}),_vm._v(" "),_c('work-time-last-active-indicator',{staticClass:"my-2",attrs:{"value":_vm.$deep(item, 'deviceStatus.working'),"last-work":_vm.$deep(item, 'deviceStatus.lastWork'),"work-time":_vm.$deep(item, 'stats.workTime')}}),_vm._v(" "),(item.deviceStatus.lastWorker)?_c('operator-last-active-indicator',{attrs:{"name":_vm.$deep(item, 'deviceStatus.lastWorker.name'),"active":_vm.$deep(item, 'deviceStatus.workerActive'),"last-sync":_vm.$deep(item, 'deviceStatus.lastWorkerActive')}}):_vm._e(),_vm._v(" "),(_vm.$deep(item.deviceStatus, 'battery') !== null)?_c('battery-voltage-indicator',{staticClass:"my-2",attrs:{"value":item.deviceStatus.battery,"battery-status":item.deviceStatus.batteryStatus}}):_vm._e(),_vm._v(" "),(item.deviceStatus.locationStatus !== null)?_c('location-indicator',{key:item.id,attrs:{"status":item.deviceStatus.locationStatus,"device-shown":true,"last-sync":item.deviceStatus.deviceLocation !== null
                ? item.deviceStatus.deviceLocation.time : null}}):_vm._e()]}},{key:"item.motorHours",fn:function(ref){
                var item = ref.item;
return [(item.deviceStatus.motorHours)?_c('motor-hours',{attrs:{"value":item.deviceStatus.motorHours}}):_vm._e()]}},{key:"item.trend",fn:function(ref){
                var item = ref.item;
return [_c('work-trend-indicator',{attrs:{"value":_vm.$deep(item.stats, 'trend')}})]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('bkf-action-edit',{on:{"click":function($event){return _vm.edit(item)}}})]}}],null,true)}):_vm._e(),_vm._v(" "),(_vm.siteLoading)?_c(VProgressCircular,{staticClass:"mb-8",attrs:{"color":"primary","size":"60","indeterminate":""}}):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('router-view'),_vm._v(" "),_c('bkf-crud',{ref:"crud",attrs:{"create-submit-label":_vm.$trans('device.create')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }