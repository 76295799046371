import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bkf-widget',{staticClass:"widget-attention"},[_c('template',{slot:"title"},[_vm._v("\n    "+_vm._s(_vm._f("trans")('widget.devices-attention.title'))+"\n  ")]),_vm._v(" "),(_vm.loaded)?_c('template',{slot:"actions"},[_c(VMenu,{attrs:{"left":"","min-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c(VIcon,[_vm._v("more_vert")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm._f("trans")('action-button.more')))])])]}}],null,false,4099994463)},[_vm._v(" "),_c(VList,[_c(VListItem,{on:{"click":_vm.openSettings}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("\n              settings\n            ")])],1),_vm._v(" "),_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("trans")('widget.settings')))])],1),_vm._v(" "),_c(VListItem,{on:{"click":function($event){return _vm.startGuide('widget-attention')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("\n              help\n            ")])],1),_vm._v(" "),_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("trans")('widget.guide')))])],1)],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.showAlarms)?[(_vm.loaded)?_c(VDataTable,{attrs:{"headers":_vm.columns,"items":_vm.devices,"item-class":_vm.rowClass,"items-per-page":10,"hide-default-footer":true},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.alarms",fn:function(ref){
var item = ref.item;
return [_c('bkf-tooltip',{attrs:{"content":_vm.getImportanceTooltip('critical')}},[(item.alertsInfo[2])?_c('bkf-alert-count',{attrs:{"value":item.alertsInfo[2],"type":"error"}}):_vm._e()],1),_vm._v(" "),_c('bkf-tooltip',{attrs:{"content":_vm.getImportanceTooltip('warning')}},[(item.alertsInfo[1])?_c('bkf-alert-count',{attrs:{"value":item.alertsInfo[1],"type":"warning"}}):_vm._e()],1),_vm._v(" "),_c('bkf-tooltip',{attrs:{"content":_vm.getImportanceTooltip('info')}},[(item.alertsInfo[0])?_c('bkf-alert-count',{attrs:{"value":item.alertsInfo[0],"type":"info"}}):_vm._e()],1)]}}],null,true)}):_c('div',{staticClass:"pv-50 center-align"},[_c(VProgressCircular,{attrs:{"size":80,"color":"primary","indeterminate":""}})],1)]:_c('bkf-empty-state',{attrs:{"show-button":false,"show-widget":false,"title":_vm.$trans('widget.devices-attention.empty')}}),_vm._v(" "),_c('settings',{ref:"settings",on:{"submit":_vm.saveSettings},model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }