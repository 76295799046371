import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"normal-text no-flex",attrs:{"small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click')}}},'v-btn',attrs,false),on),[_vm._t("default"),_vm._v(" "),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("\n        mdi-launch\n      ")])],2)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n    "+_vm._s(_vm._f("trans")(_vm.tooltip))+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }