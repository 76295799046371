import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":"65%","eager":"","transform-origin":"bottom center"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"d-flex flex-column"},[_c(VToolbar,{staticClass:"flex-grow-0",attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm._f("trans")('support.title')))]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c(VCardText,{staticClass:"pa-4 flex-grow-1"},[_c(VAlert,{staticClass:"mt-0",attrs:{"type":"info"}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('support.info'))+"\n      ")]),_vm._v(" "),_c(VForm,{ref:"form"},[_c(VTextField,{attrs:{"required":"","rules":_vm.rules,"label":_vm.$trans('support.subject')},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_vm._v(" "),_c(VTextarea,{attrs:{"rules":_vm.rules,"required":"","rows":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("\n              "+_vm._s(_vm._f("trans")('support.content'))+"\n            ")])]},proxy:true}]),model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)],1),_vm._v(" "),_c(VDivider),_vm._v(" "),_c(VCardActions,[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"color":"primary","tile":""},on:{"click":_vm.submit}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('support.submit'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }