import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"device-tab-work mb-4"},[(!_vm.loading)?[(_vm.history.length)?_c(VDataTable,{attrs:{"headers":_vm.columns,"items":_vm.history,"items-per-page":10},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('enabled-time-indicator',{attrs:{"value":item.active}})]}},{key:"item.work",fn:function(ref){
var item = ref.item;
return [_c('work-time-indicator',{attrs:{"value":item.work}})]}}],null,true)}):_c('p',{staticClass:"pv-50 center-align"},[_vm._v("\n      "+_vm._s(_vm._f("trans")('sensor.no-data'))+"\n    ")])]:_c('div',{staticClass:"pv-50 mv-50 center-align",attrs:{"name":"fade"}},[(_vm.loading)?_c('div',{staticClass:"progress"},[_c(VProgressCircular,{attrs:{"size":80,"color":"primary","indeterminate":""}})],1):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }