import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"width":"700"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{staticClass:"flex-grow-0",attrs:{"dark":"","color":"error"}},[_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm._f("trans")('productionview.delete.title')))]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c(VCardText,[_c('p',{staticClass:"mt-8"},[_vm._v("\n          "+_vm._s(_vm._f("trans")('productionview.delete.message'))+"\n          "+_vm._s(_vm.deleteName)+"\n        ")])]),_vm._v(" "),_c(VDivider),_vm._v(" "),_c(VCardActions,[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"color":"normal"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("\n          "+_vm._s(_vm._f("trans")('productionview.delete.discard'))+"\n        ")]),_vm._v(" "),_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteProductionView()}}},[_vm._v("\n          "+_vm._s(_vm._f("trans")('productionview.delete.confirm'))+"\n        ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }