import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{ref:"modal",attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":"400","eager":"","transform-origin":"bottom center"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"d-flex flex-column"},[_c(VToolbar,{staticClass:"flex-grow-0",attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("\n        "+_vm._s(_vm._f("trans")('device-operator-group.assign-device-operators'))+"\n      ")]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c(VCardText,{staticClass:"pt-4 flex-grow-1"},[_c(VForm,{nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}}},[_c(VRow,[_c(VCol,[_c(VAutocomplete,{attrs:{"label":_vm.$trans('device-group.device'),"multiple":true,"items":_vm.devices,"item-text":_vm.getSelectItemText,"item-value":_vm.getSelectItemValue},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1)],1)],1),_vm._v(" "),_c(VCardActions,[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"tile":"","color":"primary","disabled":_vm.isEmpty},on:{"click":_vm.submit}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('dialog.add'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }