import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bkf-widget',[_c('template',{slot:"title"},[_vm._v("\n    "+_vm._s(_vm._f("trans")('alert-rule.assigned-devices-and-groups'))+"\n  ")]),_vm._v(" "),(_vm.loaded && !_vm.archived)?_c('template',{slot:"actions"},[_c('bkf-action-add',{on:{"click":_vm.$refs.dialog.open}})],1):_vm._e(),_vm._v(" "),(_vm.loaded)?[(_vm.linkedDevicesAndDeviceGroups.length)?_c(VDataTable,{attrs:{"headers":_vm.columns,"items":_vm.linkedDevicesAndDeviceGroups,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("\n          "+_vm._s(item.name)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"row__device-or-device-group pt-2"},[(item.device)?[_vm._v("\n            "+_vm._s(_vm._f("trans")('alert-rule.device'))+"\n          ")]:[_vm._v("\n            "+_vm._s(_vm._f("trans")('alert-rule.device-group'))+"\n          ")]],2)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('bkf-action-delete',{on:{"click":function($event){return _vm.unlink(item)}}})]}}],null,true)}):_c(VAlert,{staticClass:"mt-0",attrs:{"type":"info"}},[_vm._v("\n      "+_vm._s(_vm._f("trans")('alert-rule.no-assigned-devices-and-device-groups'))+"\n    ")])]:_c('div',{staticClass:"pv-50 center-align"},[_c(VProgressCircular,{attrs:{"size":80,"color":"primary","indeterminate":""}})],1),_vm._v(" "),_c('assign-dialog',{ref:"dialog",attrs:{"device-groups":_vm.unlinkedDeviceGroups,"devices":_vm.unlinkedDevices},on:{"submit":_vm.link}}),_vm._v(" "),_c('bkf-page-settings',{attrs:{"settings":_vm.componentSettings},on:{"update:settings":function($event){_vm.componentSettings=$event}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }