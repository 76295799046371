import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"px-3 py-3",attrs:{"outlined":"","color":"blue-grey lighten-5"}},[_c('h3',{staticClass:"mb-3"},[_vm._v("\n    "+_vm._s(_vm.headerText)+"\n  ")]),_vm._v(" "),(_vm.items.length)?_c(VSimpleTable,{staticClass:"elevation-3 py-2",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.items),function(ref){
var key = ref[0];
var value = ref[1];
return _c('tr',{key:("item-" + key)},[_c('td',{key:("itemName-" + key),staticClass:"border-right text-left"},[_vm._v("\n            "+_vm._s(key)+"\n          ")]),_vm._v(" "),_c('td',{key:("itemValue-" + key),staticClass:"text-left"},[_vm._v("\n            "+_vm._s(value)+"\n          ")])])}),0)]},proxy:true}],null,false,1236307253)}):_c(VCardText,[_c(VIcon,{attrs:{"small":""}},[_vm._v("\n      mdi-alert-circle-outline\n    ")]),_vm._v(" "),_c('i',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("trans")(_vm.table.no_data)))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }