import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bkf-widget',{staticClass:"widget-activity-summary"},[_c('template',{slot:"title"},[_vm._v("\n    "+_vm._s(_vm._f("trans")('widget.activity-summary.title'))+"\n  ")]),_vm._v(" "),_c('template',{slot:"actions"},[_c(VMenu,{attrs:{"left":"","min-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c(VIcon,[_vm._v("more_vert")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm._f("trans")('action-button.more')))])])]}}])},[_vm._v(" "),_c(VList,[_c(VListItem,{on:{"click":_vm.openSettings}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("\n              settings\n            ")])],1),_vm._v(" "),_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("trans")('widget.settings')))])],1),_vm._v(" "),_c(VListItem,{on:{"click":function($event){return _vm.startGuide('widget-activity-summary')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("\n              help\n            ")])],1),_vm._v(" "),_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("trans")('widget.guide')))])],1)],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"flex flex-justify-center container"},[_c('chart',{attrs:{"values":_vm.values}}),_vm._v(" "),(_vm.settings.showAdditionalInfo)?_c(VList,{staticClass:"add-info flex-align-center text-body-2",attrs:{"dense":""}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItem,_vm._g({},on),[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(_vm._f("trans")('widget.activity-summary.average-work-time'))+": ")]),_vm._v(" "),_c('strong',[_vm._v(" "+_vm._s(_vm._f("human")(_vm.additionalInfo.avgDeviceWork)))])])]}}],null,false,4020853216)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("trans")('widget.activity-summary.average-work-time-explanation')))])]),_vm._v(" "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItem,_vm._g({},on),[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(_vm._f("trans")('widget.activity-summary.max-work-time'))+": ")]),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm._f("human")(_vm.additionalInfo.maxDeviceWork)))])])]}}],null,false,699249496)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("trans")('widget.activity-summary.max-work-time-explanation')))])]),_vm._v(" "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItem,_vm._g({},on),[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(_vm._f("trans")('widget.activity-summary.average-activity-time'))+":")]),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm._f("human")(_vm.additionalInfo.avgDeviceActive)))])])]}}],null,false,1025835786)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("trans")('widget.activity-summary.average-activity-time-explanation')))])]),_vm._v(" "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItem,_vm._g({},on),[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(_vm._f("trans")('widget.activity-summary.max-activity-time'))+": ")]),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm._f("human")(_vm.additionalInfo.maxDeviceActive)))])])]}}],null,false,2473270905)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("trans")('widget.activity-summary.max-activity-time-explanation')))])])],1):_vm._e()],1),_vm._v(" "),(_vm.initialized)?_c('settings',{ref:"settings",attrs:{"device-groups":_vm.deviceGroups},on:{"submit":_vm.saveSettings},model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}}):_vm._e(),_vm._v(" "),_c('bkf-refresher',{attrs:{"speed":60,"pause":_vm.loading},on:{"refresh":_vm.refresh}}),_vm._v(" "),(_vm.initialized && _vm.values.total === 0)?_c(VAlert,{staticClass:"mt-0",attrs:{"type":"warning"}},[_vm._v("\n    "+_vm._s(_vm._f("trans")('alert-rule.no-devices'))+"\n  ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }