import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","flat":"","color":_vm.headerColor,"dark":_vm.headerStyle}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.toggleMenu.apply(null, arguments)}}}),_vm._v(" "),_c(VToolbarTitle,{staticClass:"ml-0 pl-4"},[(_vm.brand === 'rejs')?_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_c('img',{staticClass:"logo",class:{'logoOnMobile': _vm.isMobile},attrs:{"src":require("@assets/images/rejs/logo.png")}}),_vm._v(" "),(!_vm.isMobile)?_c('img',{staticClass:"logo most",attrs:{"src":require("@assets/images/rejs/most.png")}}):_vm._e()]):(_vm.brand === 'precimet')?_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_c('img',{staticClass:"logo precimet",attrs:{"src":require("@assets/images/precimet/logo.png")}})]):_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_c('img',{staticClass:"logo mt-0",class:{'logoOnMobile': _vm.isMobile},attrs:{"src":require("@assets/images/default/logo.png")}})])],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VTooltip,{attrs:{"bottom":"","internal-activator":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('bkf-profile-menu',_vm._g(_vm._b({staticClass:"ml-2",on:{"guide":function($event){return _vm.$emit('guide')}}},'bkf-profile-menu',attrs,false),on))]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.user.name))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }