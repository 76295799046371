import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":"40%","eager":"","transform-origin":"bottom center"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"d-flex flex-column"},[_c(VToolbar,{staticClass:"flex-grow-0",attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c(VCardText,{staticClass:"pt-4 flex-grow-1"},[(_vm.show)?_c('bkf-form',{ref:"form",attrs:{"context":_vm.context,"model":_vm.model,"options":_vm.options},on:{"fetching":_vm.onFetching,"fetched":_vm.onFetched,"fetchError":_vm.onFetchError,"submitting":_vm.onSubmitting,"submitted":_vm.onSubmitted,"submitError":_vm.onSubmitError,"validate-error":_vm.onValidateError}}):_vm._e()],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VDivider),_vm._v(" "),_c(VCardActions,[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"tile":"","color":"primary","loading":_vm.fetching || _vm.submitting},on:{"click":_vm.submit}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('dialog.create'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }