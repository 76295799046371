import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-report"},[_c('bkf-widget',[_c('template',{slot:"title"},[_vm._v("\n      "+_vm._s(_vm._f("trans")('report.field.types'))+"\n    ")]),_vm._v(" "),_c(VList,[_vm._l((_vm.reports),function(report){return [_c(VDivider,{key:((report.name) + "_divider")}),_vm._v(" "),_c(VListItem,{key:report.name,attrs:{"to":{ name: report.name }}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v("\n              mdi-file-document\n            ")])],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(report.title))]),_vm._v(" "),_c(VListItemSubtitle,[_vm._v(_vm._s(report.description))])],1)],1)]})],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }