import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"flex flex-justify-left flex-align-items-center fs-09r"},[_c('bkf-action-previous-page',{attrs:{"tooltip":_vm.$trans('widget.effective-work.previous-day')},on:{"click":_vm.previousInterval}}),_vm._v(" "),_c('span',{staticClass:"widget-effective-work-datetime"},[_c(VMenu,{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"centered-input w-120",attrs:{"readonly":""},model:{value:(_vm.dateString),callback:function ($$v) {_vm.dateString=$$v},expression:"dateString"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_vm._v(" "),_c(VDatePicker,{attrs:{"no-title":"","first-day-of-week":"1"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.dateString),callback:function ($$v) {_vm.dateString=$$v},expression:"dateString"}})],1)],1),_vm._v(" "),_c('bkf-action-next-page',{attrs:{"tooltip":_vm.$trans('widget.effective-work.next-day')},on:{"click":_vm.nextInterval}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }