import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[(_vm.isActivePage)?_c('portal',{attrs:{"to":"page-actions"}}):_vm._e(),_vm._v(" "),(_vm.isActivePage)?_c('bkf-page',{staticClass:"pt-3",attrs:{"loading":false,"error":_vm.error}},[_c('bkf-widget',[_c(VCardTitle,[_c(VCol,{staticClass:"ml-auto d-flex",attrs:{"cols":"auto"}},[_c(VSpacer),_vm._v(" "),_c(VSelect,{staticClass:"pr-4",attrs:{"items":_vm.statuses,"label":_vm.$trans('alert.status.name'),"item-value":"id","item-text":"name"},on:{"change":_vm.getData},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_vm._v(" "),_c(VTextField,{staticClass:"font-weight-regular",attrs:{"append-icon":"search","label":_vm.$trans('table.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c(VCol,{staticClass:"pr-0 pt-7",attrs:{"cols":"auto"}},[_c('bkf-action-refresh',{on:{"click":_vm.refresh}})],1)],1),_vm._v(" "),(!_vm.siteLoading)?_c(VDataTable,{attrs:{"loading":_vm.siteLoading,"headers":_vm.columns,"items":_vm.alarms,"footer-props":_vm.footerProps,"options":_vm.pagination,"server-items-length":_vm.totalItems,"single-expand":false,"expanded":_vm.expanded,"item-key":"id"},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.alarmRule.level",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mt-n1",class:_vm.getImportanceClass(item.alarmRule.level),attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("\n                  mdi-bell\n                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.getImportanceTooltip(item.alarmRule.level)))])])]}},{key:"item.ctime",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("\n                  "+_vm._s(_vm._f("dateTime")(item.ctime))+"\n                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm._f("trans")('alert.last-occurence-time'))+": "+_vm._s(_vm._f("dateTime")(item.lastTime)))])])]}},{key:"item.endTime",fn:function(ref){
var item = ref.item;
return [(item.endTime !== null)?_c('span',[_vm._v(_vm._s(_vm._f("dateTime")(item.endTime)))]):_c('span',[_vm._v(_vm._s('-'))])]}},{key:"item.device.name",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("\n                  "+_vm._s(item.device.name)+"\n                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(item.device.description))])])]}},{key:"item.alarmRule.name",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("\n                  "+_vm._s(item.alarmRule.name)+"\n                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(_vm._f("trans")('alert.description'))+": "+_vm._s(item.alarmRule.description)+"\n              ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getStatusClass(item.status)},[_vm._v("\n              "+_vm._s(_vm._f("trans")(_vm.getStatusTranslation(item.status)))+"\n            ")])]}},{key:"item.confirm",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status === 'a')?_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.archiveAndReload({ alert: item, remove: false })}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-check")])],1):_vm._e()]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("trans")('alert.archive')))])])]}}],null,true)}):_vm._e(),_vm._v(" "),(_vm.siteLoading)?_c(VProgressCircular,{staticClass:"mb-8",attrs:{"color":"primary","size":"60","indeterminate":""}}):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }