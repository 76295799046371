import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":_vm.$trans('shifts.name'),"hide-details":""},model:{value:(_vm.shiftName),callback:function ($$v) {_vm.shiftName=$$v},expression:"shiftName"}})],1),_vm._v(" "),_c(VCol,[_c(VMenu,{ref:("menuStart" + _vm.index),attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.shiftStart,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.shiftStart=$event},"update:return-value":function($event){_vm.shiftStart=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.$trans('shifts.start'),"readonly":"","hide-details":""},model:{value:(_vm.shiftStart),callback:function ($$v) {_vm.shiftStart=$$v},expression:"shiftStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.shiftStartMenu),callback:function ($$v) {_vm.shiftStartMenu=$$v},expression:"shiftStartMenu"}},[_vm._v(" "),(_vm.shiftStartMenu)?_c(VTimePicker,{attrs:{"allowed-minutes":[0],"full-width":"","format":"24hr"},on:{"click:hour":function($event){_vm.$refs[("menuStart" + _vm.index)].save(_vm.shiftStart)}},model:{value:(_vm.shiftStart),callback:function ($$v) {_vm.shiftStart=$$v},expression:"shiftStart"}}):_vm._e()],1)],1),_vm._v(" "),_c(VCol,[_c(VMenu,{ref:("menuEnd" + _vm.index),attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.shiftEnd,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.shiftEnd=$event},"update:return-value":function($event){_vm.shiftEnd=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.$trans('shifts.stop'),"readonly":"","hide-details":""},model:{value:(_vm.shiftEnd),callback:function ($$v) {_vm.shiftEnd=$$v},expression:"shiftEnd"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.shiftEndMenu),callback:function ($$v) {_vm.shiftEndMenu=$$v},expression:"shiftEndMenu"}},[_vm._v(" "),(_vm.shiftEndMenu)?_c(VTimePicker,{attrs:{"allowed-minutes":[0],"full-width":"","format":"24hr"},on:{"click:hour":function($event){_vm.$refs[("menuEnd" + _vm.index)].save(_vm.shiftEnd)}},model:{value:(_vm.shiftEnd),callback:function ($$v) {_vm.shiftEnd=$$v},expression:"shiftEnd"}}):_vm._e()],1)],1),_vm._v(" "),_c(VCol,[_c(VBtn,{staticClass:"mt-4",attrs:{"icon":""},on:{"click":_vm.remove}},[_c(VIcon,[_vm._v("\n        mdi-delete\n      ")])],1),_vm._v(" "),(_vm.error)?_c(VIcon,{attrs:{"color":"warning"}},[_vm._v("\n      warning\n    ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }