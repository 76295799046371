import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[(_vm.isActivePage)?_c('portal',{attrs:{"to":"page-actions"}}):_vm._e(),_vm._v(" "),(_vm.isActivePage)?_c('bkf-page',{staticClass:"pt-3",attrs:{"loading":false,"error":_vm.error}},[_c('bkf-widget',[_c(VCardTitle,[_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c(VSpacer),_vm._v(" "),_c(VTextField,{staticClass:"font-weight-regular",attrs:{"append-icon":"search","label":_vm.$trans('table.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c(VCol,{staticClass:"pr-0 pt-7",attrs:{"cols":"auto"}},[_c('bkf-action-refresh',{on:{"click":_vm.refresh}})],1),_vm._v(" "),_c(VCol,{staticClass:"pr-0 pt-7",attrs:{"cols":"auto"}},[_c('bkf-action-add',{on:{"click":function($event){return _vm.add()}}})],1)],1),_vm._v(" "),(!_vm.siteLoading)?_c(VDataTable,{attrs:{"loading":_vm.siteLoading,"headers":_vm.columns,"items":_vm.administrationUsers,"footer-props":_vm.footerProps,"options":_vm.pagination,"server-items-length":_vm.totalItems,"single-expand":true,"expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.subscriptionExpire",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("date")(item.subscriptionExpire))+"\n          ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("\n                  "+_vm._s(item.name)+"\n                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(item.description))])])]}},{key:"item.devicesCount",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.devices.length)+"\n          ")]}},{key:"item.usersCount",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.users.length)+"\n          ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-2",attrs:{"colspan":headers.length}},[(item.devices !== null && Object.keys(item.devices).length > 0)?_c('devices-table',{key:("user-devices-" + (item.id)),attrs:{"data":item.devices,"header-text":_vm.$trans("menu.devices")}}):_vm._e(),_vm._v(" "),(item.users !== null && Object.keys(item.users).length > 0)?_c('users-table',{key:("user-group-users-" + (item.id)),attrs:{"data":item.users,"header-text":_vm.$trans("menu.users")}}):_vm._e(),_vm._v(" "),(item.ai !== null && Object.keys(item.ai).length > 0)?_c('details-table',{key:("user-group-ai-" + (item.id)),attrs:{"data":item.ai,"header-text":_vm.$trans("additional-info")}}):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('bkf-action-edit',{on:{"click":function($event){return _vm.edit(item)}}})]}}],null,true)}):_vm._e(),_vm._v(" "),_c('user-group-edit-modal',{ref:"user-group-edit-modal",attrs:{"group-id":_vm.editUserGroupId,"title":_vm.$trans('user-group.edit')},on:{"on-change":function($event){return _vm.refresh()}}}),_vm._v(" "),_c('user-group-add-modal',{ref:"user-group-add-modal",attrs:{"title":_vm.$trans('user-group.add')},on:{"on-change":function($event){return _vm.refresh()}}}),_vm._v(" "),(_vm.siteLoading)?_c(VProgressCircular,{staticClass:"mb-8",attrs:{"color":"primary","size":"60","indeterminate":""}}):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('router-view'),_vm._v(" "),_c('bkf-crud',{ref:"crud",attrs:{"create-submit-label":_vm.$trans('device.create')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }