import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":"550","eager":"","transform-origin":"bottom center"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"d-flex flex-column"},[_c(VToolbar,{staticClass:"flex-grow-0",attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm._f("trans")('profile.impersonate')))]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c(VCardText,{staticClass:"pt-4 flex-grow-1"},[_c(VAutocomplete,{attrs:{"placeholder":_vm.$trans('profile.choose'),"clearable":true,"filter":_vm.findUser,"no-data-text":' ',"items":_vm.users,"item-value":_vm.getSelectItemValue},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(item.name)}}),_vm._v(" "),_c(VListItemSubtitle,{staticClass:"text--primary text-caption",domProps:{"textContent":_vm._s(item.username)}}),_vm._v(" "),_c(VListItemSubtitle,{staticClass:"pt-1",domProps:{"textContent":_vm._s(item.user_group.userGroup)}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.name)}})]}}]),model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VDivider),_vm._v(" "),_c(VCardActions,[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"tile":"","color":"primary","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('dialog.change'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }