import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bkf-widget',[_c('template',{slot:"title"},[_vm._v("\n    "+_vm._s(_vm._f("trans")('alert-rule.conditions'))+"\n  ")]),_vm._v(" "),(!_vm.archived)?_c('template',{slot:"actions"},[(_vm.alertRule.type !== 'authentication' && _vm.alertRule.type !== 'work without operator')?_c('bkf-action-add',{on:{"click":_vm.create}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.conditions.length)?_c(VDataTable,{attrs:{"headers":_vm.alertRuleColumns,"items":_vm.conditions,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.condition",fn:function(ref){
var item = ref.item;
return [_c('condition',{attrs:{"alert-rule":_vm.alertRule,"row":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('bkf-action-edit',{on:{"click":function($event){return _vm.edit(item)}}}),_vm._v(" "),_c('bkf-action-delete',{on:{"click":function($event){return _vm.remove(item)}}})]}}],null,true)}):[(_vm.alertRule.type !== 'authentication' && _vm.alertRule.type !== 'work without operator')?_c(VAlert,{staticClass:"mt-0",attrs:{"type":"warning","tile":""}},[_vm._v("\n      "+_vm._s(_vm._f("trans")('alert-rule.no-conditions-add-new'))+"\n    ")]):_c(VAlert,{staticClass:"mt-0",attrs:{"type":"info","tile":""}},[_vm._v("\n      "+_vm._s(_vm._f("trans")('alert-rule.no-conditions-needed'))+"\n    ")])],_vm._v(" "),_c('bkf-crud',{ref:"crud",attrs:{"create-title":_vm.$trans('alert-rule.condition.creating'),"update-title":_vm.$trans('alert-rule.condition.editing')},on:{"created":_vm.created,"updated":_vm.updated,"deleted":_vm.deleted}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }