import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bkf-widget',{staticClass:"widget-effective-work"},[_c('div',{staticClass:"flex flex-align-items-center",attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v(_vm._s(_vm._f("trans")('widget.effective-work.title')))])]),_vm._v(" "),(_vm.loaded)?_c('interval',{class:{'pb-10 flex-justify-center' : _vm.isMobile},attrs:{"hours":_vm.settings.interval},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}):_vm._e(),_vm._v(" "),(_vm.isMobile)?_c('div',{staticClass:"center-align pt-10 pb-10 averageOnMobile"},[_vm._v("\n    "+_vm._s(_vm._f("trans")('widget.effective-work.average-value'))+": "+_vm._s(_vm.avgWork)+"%\n  ")]):_vm._e(),_vm._v(" "),(_vm.loaded)?_c('template',{slot:"actions"},[_c(VMenu,{attrs:{"left":"","min-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c(VIcon,[_vm._v("more_vert")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm._f("trans")('action-button.more')))])])]}}],null,false,4099994463)},[_vm._v(" "),_c(VList,[_c(VListItem,{on:{"click":_vm.openSettings}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("\n              settings\n            ")])],1),_vm._v(" "),_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("trans")('widget.settings')))])],1),_vm._v(" "),_c(VListItem,{on:{"click":function($event){return _vm.startGuide('widget-effective-work')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("\n              help\n            ")])],1),_vm._v(" "),_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("trans")('widget.guide')))])],1)],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.loaded)?[_c('settings',{ref:"settings",attrs:{"device-groups":_vm.deviceGroups},on:{"submit":_vm.saveSettings},model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}}),_vm._v(" "),(_vm.fetching)?_c('div',{staticClass:"center-align pv-50"},[_c(VProgressCircular,{attrs:{"size":80,"color":"primary","indeterminate":""}})],1):[(_vm.data.length)?_c('chart',{attrs:{"date":_vm.date,"data":_vm.data,"interval":_vm.settings.interval},on:{"calc-sum":_vm.calcAvg}}):_c('bkf-empty-state',{attrs:{"show-widget":false,"show-button":false}})]]:_c('div',{staticClass:"pv-50 center-align"},[_c(VProgressCircular,{attrs:{"size":80,"color":"primary","indeterminate":""}})],1),_vm._v(" "),_c('bkf-refresher',{attrs:{"speed":60,"pause":_vm.fetching},on:{"refresh":_vm.refresh}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }