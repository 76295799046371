import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.conditions.length)?_c(VDataTable,{attrs:{"headers":_vm.alertRuleColumns,"items":_vm.conditions,"items-per-page":10,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?[_vm._v("\n        "+_vm._s(item.parameter.name)+"\n      ")]:[_vm._v("\n        -\n      ")]]}},{key:"item.condition",fn:function(ref){
var item = ref.item;
return [_c('condition',{attrs:{"row":item,"alert-rule":_vm.alertRule}})]}}],null,true)}):[_c(VAlert,{staticClass:"mt-0",attrs:{"type":"info"}},[_vm._v("\n      "+_vm._s(_vm._f("trans")('alert-rule.no-conditions'))+"\n    ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }