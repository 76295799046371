import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c('transition',{attrs:{"name":"page-switch","mode":"out-in"}},[(_vm.isActivePage)?_c('bkf-page',{staticClass:"pt-3",attrs:{"loading":_vm.loading,"error":_vm.error}},[(_vm.sensors.length)?_c('bkf-widget',{staticClass:"pt-4"},[_c(VCardTitle,[_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c(VSpacer),_vm._v(" "),_c(VTextField,{staticClass:"font-weight-regular",attrs:{"append-icon":"search","label":_vm.$trans('table.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_vm._v(" "),_c(VDataTable,{attrs:{"headers":_vm.columns,"items":_vm.sensors,"item-class":_vm.rowClass,"items-per-page":_vm.settings.size,"search":_vm.search},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.device.name",fn:function(ref){
var item = ref.item;
return [(item.device)?_c('device-button',{attrs:{"device":item.device}}):_vm._e()]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('module-activity-indicator',{staticClass:"py-2",attrs:{"value":_vm.$deep(item, 'active'),"last-sync":_vm.$deep(item, 'lastSynchroTime')}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('bkf-action-edit',{on:{"click":function($event){return _vm.edit(item)}}})]}}],null,true)})],1):_c('bkf-empty-state')],1):_c('router-view')],1),_vm._v(" "),_c('bkf-crud',{ref:"crud"}),_vm._v(" "),_c('bkf-page-settings',{attrs:{"settings":_vm.settings},on:{"update:settings":function($event){_vm.settings=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }