import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bkf-widget',[_c('template',{slot:"title"},[_vm._v("\n    "+_vm._s(_vm._f("trans")('device-group.assigned-devices'))+"\n  ")]),_vm._v(" "),(_vm.loaded)?_c('template',{slot:"actions"},[_c('bkf-action-add',{attrs:{"tooltip":_vm.$trans('action-button.assign')},on:{"click":_vm.$refs.dialog.open}})],1):_vm._e(),_vm._v(" "),(_vm.loaded)?[(_vm.linkedDevices.length)?_c(VDataTable,{attrs:{"headers":_vm.columns,"items":_vm.linkedDevices,"item-class":_vm.rowClass,"items-per-page":_vm.settings.size},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('sensor-indicator',{attrs:{"last-sync":_vm._f("deep")(item.diagnostics,'operator_last_active.date')}}),_vm._v("\n        "+_vm._s(item.name)+"\n      ")]}},{key:"item.diagnostics.active",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm._f("human")(_vm.getDeviceActiveTime(item)))+"\n      ")]}},{key:"item.diagnostics.work",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm._f("human")(_vm.getDeviceWorkTime(item)))+"\n      ")]}},{key:"item.productivity",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.getDeviceProductivity(item))+"%\n      ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('bkf-action-delete',{on:{"click":function($event){return _vm.unlink([item.id])}}})]}}],null,true)}):_c(VAlert,{staticClass:"mt-0",attrs:{"type":"info","tile":""}},[_vm._v("\n      "+_vm._s(_vm._f("trans")('device-group.no-devices'))+"\n    ")])]:_c('div',{staticClass:"pv-50 center-align"},[_c(VProgressCircular,{attrs:{"size":80,"color":"primary","indeterminate":""}})],1),_vm._v(" "),_c('assign-dialog',{ref:"dialog",attrs:{"devices":_vm.unlinkedDevices},on:{"submit":_vm.link}}),_vm._v(" "),_c('bkf-page-settings',{attrs:{"settings":_vm.componentSettings},on:{"update:settings":function($event){_vm.componentSettings=$event}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }