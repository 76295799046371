import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"normal-text no-flex",attrs:{"small":"","text":""},on:{"click":_vm.goToDevice}},'v-btn',attrs,false),on),[_vm._v("\n      "+_vm._s(_vm.device.name)+"\n      "),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("\n        mdi-launch\n      ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("\n    "+_vm._s(_vm._f("trans")('device.go-to-device'))+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }