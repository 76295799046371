import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"device-tab-work mb-4"},[_c('div',{staticClass:"flex flex-align-items-center flex-justify-end"},[_c('div',{staticClass:"year flex"},[_c('bkf-action-previous-page',{on:{"click":_vm.subYear}}),_vm._v(" "),_c('div',{staticClass:"fs-11r mt-1"},[_vm._v("\n        "+_vm._s(_vm.year)+"\n      ")]),_vm._v(" "),_c('bkf-action-next-page',{on:{"click":_vm.addYear}})],1),_vm._v(" "),_c('div',{staticClass:"mr-2"},[_vm._v("\n      "+_vm._s(_vm._f("trans")('device-operator.when-operator'))+":\n    ")]),_vm._v(" "),_c(VRadioGroup,{attrs:{"mandatory":true,"row":"","dense":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VRadio,_vm._g({attrs:{"label":_vm.$trans('device-operator.was-worked-effectively'),"value":"work"}},on))]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("trans")('device-operator.worked-efficient-explanation')))])]),_vm._v(" "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VRadio,_vm._g({attrs:{"label":_vm.$trans('device-operator.was-enabled'),"value":"active"}},on))]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("trans")('device-operator.was-active-explanation')))])])],1)],1),_vm._v(" "),_c('div',{staticClass:"chart"},[_c('e-charts',{ref:"heatmap",staticClass:"echarts-heatmap",attrs:{"options":_vm.heatmap,"autoresize":true}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }