import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bkf-widget',[_c(VCardTitle,[_vm._v("\n    "+_vm._s(_vm._f("trans")('device-operator.work-history'))+"\n  ")]),_vm._v(" "),(!_vm.loading)?[(_vm.devicesHistory.length)?_c(VDataTable,{attrs:{"headers":_vm.columns,"items":_vm.devicesHistory,"items-per-page":5},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(item.name)+"\n        "),_c('div',{staticClass:"row__device-type"},[_c('strong',[_vm._v("Start:")]),_vm._v(" "+_vm._s(item.begin)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"row__device-type"},[_c('strong',[_vm._v("Koniec:")]),_vm._v(" "+_vm._s(item.end)+"\n        ")])]}},{key:"item.activity",fn:function(ref){
var item = ref.item;
return [_c('enabled-time-indicator',{attrs:{"value":item.active}}),_vm._v(" "),_c('work-time-indicator',{attrs:{"value":item.work}})]}}],null,true)}):_c('bkf-empty-state',{attrs:{"show-widget":false,"show-button":false}})]:_c('div',{staticClass:"pv-50 center-align",attrs:{"name":"fade"}},[(_vm.loading)?_c('div',{staticClass:"progress"},[_c(VProgressCircular,{attrs:{"size":80,"color":"primary","indeterminate":""}})],1):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }