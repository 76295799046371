import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,{staticClass:"d-flex"},[_c(VSpacer),_vm._v(" "),_c(VSwitch,{attrs:{"label":"Pokaż potwierdzone alarmy"},on:{"change":_vm.filterConfirmedAlerts},model:{value:(_vm.confirmedAlertsSwitch),callback:function ($$v) {_vm.confirmedAlertsSwitch=$$v},expression:"confirmedAlertsSwitch"}})],1),_vm._v(" "),_c(VDataTable,{attrs:{"headers":_vm.columns,"items":_vm.alerts,"item-class":_vm.rowClass,"items-per-page":10},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.ctime",fn:function(ref){
var item = ref.item;
return [_c('bkf-tooltip',{attrs:{"content":_vm.getImportanceTooltip(item.importance)}},[_c('bkf-alert')],1),_vm._v("\n      "+_vm._s(_vm._f("date")(item.ctime,'YYYY-MM-DD HH:mm'))+"\n    ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }