import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[(_vm.isActivePage)?_c('portal',{attrs:{"to":"page-actions"}}):_vm._e(),_vm._v(" "),(_vm.isActivePage)?_c('bkf-page',{staticClass:"pt-3",attrs:{"loading":false,"error":_vm.error}},[_c('bkf-widget',[_c(VCardTitle,[_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c(VSpacer),_vm._v(" "),_c(VTextField,{staticClass:"font-weight-regular",attrs:{"append-icon":"search","label":_vm.$trans('table.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c(VCol,{staticClass:"pr-0 pt-7",attrs:{"cols":"auto"}},[_c('bkf-action-refresh',{on:{"click":_vm.refresh}})],1),_vm._v(" "),_c(VCol,{staticClass:"pr-0 pt-7",attrs:{"cols":"auto"}},[_c('bkf-action-add',{on:{"click":function($event){return _vm.add()}}})],1)],1),_vm._v(" "),(!_vm.siteLoading)?_c(VDataTable,{attrs:{"loading":_vm.siteLoading,"headers":_vm.columns,"items":_vm.administrationDevices,"footer-props":_vm.footerProps,"options":_vm.pagination,"server-items-length":_vm.totalItems,"single-expand":true,"expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.deviceStatus",fn:function(ref){
var item = ref.item;
return [_c('module-activity-indicator',{staticClass:"my-2",attrs:{"value":item.deviceStatus.online,"last-sync":_vm.$deep(item, 'deviceStatus.lastOnline')}}),_vm._v(" "),(_vm.$deep(item.deviceStatus, 'battery') !== null)?_c('battery-voltage-indicator',{staticClass:"my-2",attrs:{"value":item.deviceStatus.battery,"battery-status":item.deviceStatus.batteryStatus}}):_vm._e(),_vm._v(" "),(item.deviceStatus.lastWorker)?_c('operator-last-active-indicator',{attrs:{"name":_vm.$deep(item, 'deviceStatus.lastWorker.name'),"active":_vm.$deep(item, 'deviceStatus.workerActive'),"last-sync":_vm.$deep(item, 'deviceStatus.lastWorkerActive')}}):_vm._e(),_vm._v(" "),_c('enabled-time-last-active-indicator',{staticClass:"my-2",attrs:{"is-active":item.deviceStatus.active,"last-sync":_vm.$deep(item, 'deviceStatus.lastActive')}}),_vm._v(" "),_c('work-time-last-active-indicator',{staticClass:"my-2",attrs:{"value":_vm.$deep(item, 'deviceStatus.working'),"last-work":_vm.$deep(item, 'deviceStatus.lastWork'),"work-time":_vm.$deep(item, 'stats.workTime')}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('bkf-action-edit',{on:{"click":function($event){return _vm.edit(item)}}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-2",attrs:{"colspan":headers.length}},[_c(VCard,{staticClass:"px-3 py-3",attrs:{"outlined":"","color":"blue-grey lighten-5"}},[_c('h3',{staticClass:"mb-3"},[_vm._v("\n                  "+_vm._s(_vm._f("trans")('menu.sensors'))+"\n                ")]),_vm._v(" "),(item.sensors.length > 0)?_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"text-sm-start"},[_vm._v("\n                          "+_vm._s(_vm._f("trans")('id'))+"\n                        ")]),_vm._v(" "),_c('td',{staticClass:"text-sm-end"},[_vm._v("\n                          "+_vm._s(_vm._f("trans")('sensor.name'))+"\n                        ")])]),_vm._v(" "),_vm._l((item.sensors),function(sensor){return _c('tr',{key:sensor.id},[_c('td',{staticClass:"text-sm-start"},[_vm._v("\n                          "+_vm._s(sensor.id)+"\n                        ")]),_vm._v(" "),_c('td',{staticClass:"text-sm-end"},[_vm._v("\n                          "+_vm._s(sensor.name)+"\n                        ")])])})],2)]},proxy:true}],null,true)}):_vm._e()],1),_vm._v(" "),(item.ai !== null && Object.keys(item.ai).length > 0)?_c('details-table',{key:("device-ai-" + (item.id)),attrs:{"data":item.ai,"header-text":_vm.$trans("additional-info")}}):_vm._e()],1)]}}],null,true)}):_vm._e(),_vm._v(" "),_c('edit-device-modal',{ref:"device-edit-modal",attrs:{"device-id":_vm.editDeviceId,"title":_vm.$trans('device.edit')},on:{"on-change":function($event){return _vm.refresh()}}}),_vm._v(" "),_c('add-device-modal',{ref:"device-add-modal",attrs:{"title":_vm.$trans('device.add')},on:{"on-change":function($event){return _vm.refresh()}}}),_vm._v(" "),(_vm.siteLoading)?_c(VProgressCircular,{staticClass:"mb-8",attrs:{"color":"primary","size":"60","indeterminate":""}}):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }