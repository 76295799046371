import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[(_vm.isActivePage)?_c('portal',{attrs:{"to":"page-actions"}},[(_vm.isMobile)?_c('bkf-floating-button-add',{attrs:{"tooltip":_vm.$trans('productionview.create')},nativeOn:{"click":function($event){return _vm.prodviewconfigurator.apply(null, arguments)}}}):_vm._e(),_vm._v(" "),_c(VBtn,{attrs:{"tile":"","color":"primary"},nativeOn:{"click":function($event){return _vm.openMyDialog.apply(null, arguments)}}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('productionview.create'))+"\n      ")])],1):_vm._e(),_vm._v(" "),(_vm.isActivePage)?_c('bkf-page',{staticClass:"pt-3",attrs:{"loading":_vm.loading,"error":_vm.error}},[(_vm.productionview.length)?_c('bkf-widget',[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.productionview},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-4 pb-2"},[_vm._v("\n              "+_vm._s(item.name)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"caption blue-grey--text lighten-1"},_vm._l((item.devices),function(device,key){return _c('div',{key:key,staticClass:"pb-2"},[_vm._v("\n                "+_vm._s(device.name)+"\n              ")])}),0)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('bkf-action-delete',{attrs:{"tooltip":_vm.$trans('productionview.delete')},on:{"click":function($event){return _vm.removeProductionView(item.id, item.name)}}}),_vm._v(" "),_c('bkf-action-edit',{attrs:{"tooltip":_vm.$trans('productionview.editicon')},on:{"click":function($event){return _vm.editProductionView(item.id)}}}),_vm._v(" "),_c(VBtn,{staticClass:"ml-2",attrs:{"color":"primary","small":"","tile":"","href":"/monitor/#/" + item.id}},[_vm._v("\n              "+_vm._s(_vm._f("trans")('productionview.view'))+"\n            ")])]}}],null,true)})],1):_c('bkf-empty-state')],1):_vm._e(),_vm._v(" "),_c('production-view-configurator',{attrs:{"title":_vm.title,"show-dialog":_vm.showDialog,"edit-prod-view":_vm.editProdView,"edited-production-view":_vm.editedProductionView,"prodviewtype":_vm.prodviewtype},on:{"send-parameters":_vm.sendParameters,"open-configurator-dialog":_vm.openConfiguratorDialog,"close-configurator-dialog":_vm.closeConfiguratorDialog}}),_vm._v(" "),_c('delete-dialog',{attrs:{"dialog":_vm.dialog,"delete-id":_vm.deleteId,"delete-name":_vm.deleteName},on:{"close-dialog":_vm.closeDialog,"delete-production-view":_vm.deleteProductionView}}),_vm._v(" "),_c('router-view')],1),_vm._v(" "),_c('bkf-crud',{ref:"crud",attrs:{"create-submit-label":_vm.$trans('productionview.create')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }