import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":"550","eager":"","transform-origin":"bottom center"},on:{"input":function (v) { return v || _vm.submit(); }},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"d-flex flex-column"},[_c(VToolbar,{staticClass:"flex-grow-0",attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm._f("trans")('widget.effective-work.config')))]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c(VCardText,{staticClass:"pt-4 flex-grow-1"},[_c(VRadioGroup,{attrs:{"mandatory":true,"row":"","dense":""},model:{value:(_vm.context),callback:function ($$v) {_vm.context=$$v},expression:"context"}},[_c(VRadio,{attrs:{"label":_vm.$trans('widget.efficiency-summary.settings.all-devices'),"value":"devices"}}),_vm._v(" "),_c(VRadio,{attrs:{"label":_vm.$trans('widget.efficiency-summary.settings.selected-device-groups'),"value":"deviceGroups"}})],1),_vm._v(" "),(_vm.context === 'deviceGroups')?_c('div',[_c(VAutocomplete,{attrs:{"items":_vm.deviceGroups,"multiple":true,"chips":"","small-chips":"","deletable-chips":"","item-text":"name","item-value":"id","label":_vm.$trans('widget.activity-summary.settings.device-groups')},model:{value:(_vm.value.deviceGroups),callback:function ($$v) {_vm.$set(_vm.value, "deviceGroups", $$v)},expression:"value.deviceGroups"}})],1):_vm._e()],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VDivider),_vm._v(" "),_c(VCardActions,[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"tile":"","color":"primary"},on:{"click":_vm.submit}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('dialog.save'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }