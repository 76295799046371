import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bkf-widget',[_c('template',{slot:"title"},[_vm._v("\n    "+_vm._s(_vm._f("trans")('device-operator-group.statistics'))+"\n  ")]),_vm._v(" "),_c(VList,{attrs:{"dense":""}},[_c(VListItem,{staticClass:"hmin-30"},[_c(VListItemContent,{staticClass:"flex-none mr-2 text-subtitle-2 py-1"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("\n              "+_vm._s(_vm._f("trans")('device-operator-group.today-activity'))+":\n            ")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("trans")('device-operator-group.average-activity-time')))])])],1),_vm._v(" "),_c(VListItemContent,{staticClass:"flex-grow-1 text-body-2 py-1"},[_vm._v("\n        "+_vm._s(_vm._f("human")(_vm.getGroupStats.active))+"\n      ")])],1),_vm._v(" "),_c(VListItem,{staticClass:"hmin-30"},[_c(VListItemContent,{staticClass:"flex-none mr-2 text-subtitle-2 py-1"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("\n              "+_vm._s(_vm._f("trans")('device-group.today-work'))+":\n            ")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("trans")('device-operator-group.average-work-time')))])])],1),_vm._v(" "),_c(VListItemContent,{staticClass:"flex-grow-1 text-body-2 py-1"},[_vm._v("\n        "+_vm._s(_vm._f("human")(_vm.getGroupStats.work))+"\n      ")])],1),_vm._v(" "),_c(VListItem,{staticClass:"hmin-30"},[_c(VListItemContent,{staticClass:"flex-none mr-2 text-subtitle-2 py-1"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("\n              "+_vm._s(_vm._f("trans")('device-group.today-productivity'))+":\n            ")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("trans")('device-operator-group.average-productivity-time')))])])],1),_vm._v(" "),_c(VListItemContent,{staticClass:"flex-grow-1 text-body-2 py-1"},[_vm._v("\n        "+_vm._s(_vm.getGroupStats.productivity)+"%\n      ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }