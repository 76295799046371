import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":"50%","eager":"","transform-origin":"bottom center"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"d-flex flex-column"},[_c(VToolbar,{staticClass:"flex-grow-0",attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c(VCardText,{staticClass:"pa-0 flex-grow-1"},[(_vm.showDialog && _vm.reportsType)?_c('bkf-report-creator',{attrs:{"reports-type":_vm.reportsType,"model":_vm.model},on:{"submitted":_vm.onSubmitted}}):_vm._e(),_vm._v(" "),(_vm.showDialog && _vm.newAlarm)?_c('alarm-creator',{attrs:{"model":_vm.model},on:{"submitted":_vm.onSubmitted}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }