import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VForm,{ref:"stepThreeForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VAutocomplete,{attrs:{"items":_vm.items,"label":_vm.$trans('productionview.resethours'),"rules":_vm.resetRule,"multiple":"","required":""},model:{value:(_vm.resetHour),callback:function ($$v) {_vm.resetHour=$$v},expression:"resetHour"}}),_vm._v(" "),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"sm":_vm.editing || _vm.avatar?4:12}},[_c(VFileInput,{attrs:{"id":"file","accept":"image/png, image/jpeg","label":_vm.editing ?_vm.$trans('productionview.logo.change'):_vm.$trans('productionview.logo.set'),"name":"file","rules":!_vm.fileBase ? _vm.fileRule : [],"clearable":false},on:{"change":function($event){return _vm.convertImage($event)}}})],1),_vm._v(" "),(_vm.editing || _vm.avatar)?_c(VCol,{staticClass:"text-center hidden",attrs:{"sm":_vm.editing || _vm.avatar?4:0}},[(_vm.editing)?_c(VImg,{attrs:{"src":_vm.fileBase}}):_vm._e(),_vm._v(" "),(_vm.avatar)?_c(VImg,{attrs:{"src":_vm.avatar}}):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }