import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"px-3 py-3",attrs:{"outlined":"","color":"blue-grey lighten-5"}},[(_vm.devices.length > 0)?_c(VExpansionPanels,[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c('h4',[_vm._v("\n          "+_vm._s(_vm.headerText)+": "+_vm._s(_vm.devices.length)+"\n        ")])]),_vm._v(" "),_c(VExpansionPanelContent,[(_vm.devices.length > 0)?_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-sm-start bold"},[_vm._v("\n                  "+_vm._s(_vm._f("trans")('id'))+"\n                ")]),_vm._v(" "),_c('th',{staticClass:"text-sm-start"},[_vm._v("\n                  "+_vm._s(_vm._f("trans")('name'))+"\n                ")]),_vm._v(" "),_c('th',{staticClass:"text-sm-start"},[_vm._v("\n                  "+_vm._s(_vm._f("trans")('users.subscription-expire'))+"\n                ")])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.devices),function(device){return _c('tr',{key:device.id},[_c('td',{staticClass:"text-sm-start"},[_vm._v("\n                  "+_vm._s(device.id)+"\n                ")]),_vm._v(" "),_c('td',{staticClass:"text-sm-start"},[_vm._v("\n                  "+_vm._s(device.name)+"\n                ")]),_vm._v(" "),_c('td',{staticClass:"text-sm-start"},[_vm._v("\n                  "+_vm._s(_vm._f("date")(device.subscriptionExpire))+"\n                ")])])}),0)]},proxy:true}],null,false,915457755)}):_c(VCardText,[_c(VIcon,{attrs:{"small":""}},[_vm._v("\n            mdi-alert-circle-outline\n          ")]),_vm._v(" "),_c('i',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("trans")(_vm.table.no_data)))])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }